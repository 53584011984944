.AdminDashboard{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    /* background-color: red; */
    /* padding: 100px 0px; */
    position: relative;
    min-height: 500px;

  }

  .AdminDashboardMenu{
    width: 30%;
    /* background-color: rgba(255, 166, 0, 0.1); */
    background-color: lightgray;
    padding: 10px;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    gap: 5px;
    /* height: 500px; */
    min-height: 100%;
    padding-top: 50px;
  }

  .AdminDashboardMenuP{
    cursor: pointer;
    color: #003366;
    font-weight: 500;
    /* text-decoration: underline; */
    padding: 5px 10px;
    border-radius: 5px;
  }

  .AdminDashboardMenuPActive{
    cursor: pointer;
    color: white;
    background-color: #003366; 
    /* font-weight: 500; */
    /* text-decoration: underline; */
    padding: 5px 10px;
    border-radius: 5px;
  }

  .AdminDashboardContentWrap{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 5px;
    /* height: 500px; */
    /* min-height: 100vh; */
    /* overflow-y: scroll; */
    
  }

.Bars{
  display: none;
  /* margin-top: 10px; */
  cursor: pointer;
  color: #003366;
  position: absolute;
  top: 20px;
  left:20px;

}

  @media screen and (max-width:428px) {
      .AdminDashboardMenu{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        overflow-y: scroll;
        width: 70%;
        z-index: 999;
        display: none;

      }

      .Bars{
        display: flex;
      }
  }