.UserLoginWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 60px 0px;
}

.UserLoginUp{
    display: flex;
    justify-content: center;
    /* align-items: center;  */
    width: 100%;
    flex-direction: row ;
    margin-bottom: 100px;
}


.UserLoginUpLeft{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width:50%;
    gap: 10px;
    padding-left: 20%;
}

.UserFormGroup{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 300px;
    gap:5px;
    /* background-color: red   ; */
}

.UserFormGroup input{
    padding: 10px;
    width: 100%;
    border: 1px solid lightgray;
    outline: none;
}

.UserLoginButton{
    width: 100%;
    background-color: #FFB42C;
    border-radius: 5px;
    border: none;
    padding: 15px;
    margin-top: 30px;
    cursor: pointer;
    font-size: 0.9rem;
}

.UserLoginUpRight{
    width: 50%;
    padding: 0px 50px;
}

.UserLoginUpLine{
    width: 1px;
    background-color: lightgray;
}

.UserLoginDown{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.UserLoginDown img{
    width: 40%;
    
}

.ContinueButton{
    width: 300px;
}

@media screen and (max-width:884px) {
    .UserLoginUp{
        flex-direction: column;
        align-items: center;
        width: 100%;
        /* padding: 5px; */
    }
    .UserLoginUpLeft{
        padding-left: 0%;
        padding: 10px;
        width: 100%;
        /* background-color: blue; */
    }
    .UserFormGroup{
        margin-top: 10px;
        width: 100%;
        /* background-color: red; */

    }
    .UserLoginUpRight{
        width: 100%;
        margin-top: 50px;
        padding: 10px;
    }

}

@media screen and (max-width:428px) {
    .UserLoginDown img{
       width: 100%;
    }
    .ContinueButton{
        width: 100%;
    }
    /* .UserLoginUpLeft{
        width: 100%;
        background-color: blue;

    }
    .UserLoginUpRight{
        width: 100%;
    } */
}