.subCategoryGrid {
display: flex;
justify-content: center;
align-items: flex-start;
gap: 20px;
flex-wrap: wrap;
width: 100%;
margin-top: 20px;
  }
  
  .subCategoryItem {
    text-align: center;
  }
  
  .subCategoryItem img {
    width: 200px;
    height: 350px;
    border: 1px solid lightgray;
    cursor: pointer;
    border-radius: 5px;
  }

  .subCategoryItem img:hover{
    border: 1px solid gray;
    box-shadow: 0px 4px 10px rgba(0,0,0,0.5);
  }
  

  @media screen and (max-width:428px) {
    .subCategoryItem img{
        width: 150px;
        height: 250px;
    }
  }