
.searchProduct-container {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
}

.searchProduct-box {
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    margin-bottom: 20px;
}

.searchProduct-box h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.searchProduct-inputGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: column;
    gap: 10px;
}

.searchProduct-inputGroup input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.searchProduct-inputGroup button {
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.searchProduct-inputGroup button:hover {
    background-color: #218838;
}

/* Product list and cards */
.searchProduct-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    width: 100%;
}

.searchProduct-card {
    width: 100%;
    max-width: 250px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.searchProduct-card img {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.searchProduct-card h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.searchProduct-card p {
    font-size: 16px;
    color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
    .searchProduct-box {
        /* max-width: 90%; */
    }

    .searchProduct-list {
        gap: 10px;
    }

    .searchProduct-card {
        max-width: 45%;
    }
}

@media (max-width: 576px) {
    .searchProduct-card {
        max-width: 100%;
    }
}
