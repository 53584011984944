.orders-container {
    padding: 20px;
    max-width: 800px;
    margin: auto;
}

.orders-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.order-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.order-item {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex: 1 0 45%; /* 2 items per row */
}

.order-item img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

@media (max-width: 600px) {
    .order-item {
        flex: 1 0 100%; /* 1 item per row on small screens */
    }
}
