*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.MainCategories{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 65%;
    height: 200px;
    gap: 10px;
    margin-top: 10px;
  
    
}

.MainCategory{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;
    /* background-color: blue; */
    border:1px solid lightgray;
    cursor: pointer;
}

.MainCategory:hover{
    border: 1px solid #CB6B37;
}

.MainCategory p{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #F9F9F9;
    color: rgba(0,0,0,0.9);
}

.MainCategory img{
    width: 100%;
    height: 80%;
}

@media screen and (max-width:884px) {
    .MainCategories{
        width: 100%;
        justify-content: center;
        height: auto;
        overflow-y: scroll;
    }
}